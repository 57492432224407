import _ from 'lodash';
import jwtHelper from './utils/JwtHelper';
import { AuthenticationService } from './AuthenticationServiceFactory';
import { getGrantedAuthorities } from './utils/GrantedAuthoritiesUtil';

// EXPORT
export class OAuthService extends AuthenticationService {
    constructor(authConfig) {
        // singleton
        if (OAuthService.instance) {
            if (!_.isEqual(authConfig, OAuthService.instance.authConfig)) {
                throw new Error('Attempt to create another OAuthService with a different configuration');
            }
            // eslint-disable-next-line no-constructor-return
            return OAuthService.instance;
        }
        super(authConfig);
        OAuthService.instance = this;
    }

    refreshAccessToken = () => {
        this.oAuthUtil.removeTokens();
        return this.oAuthUtil.requestAccessToken({ onRefreshError: this.logout });
    };

    // deprecated
    get accessToken() {
        return this.oAuthUtil.waitTokensSet()
            .then((tokens) => {
                return tokens.accessToken;
            });
    }

    getAccessToken = async () => {
        const tokens = await this.oAuthUtil.waitTokensSet();
        return tokens.accessToken;
    };

    tokenScope = () => {
        return this.oAuthUtil.getTokenScope();
    };

    accessTokenScope = (scope) => {
        console.log('i am here in accessTokenScope', scope);
        this.oAuthUtil.setTokenScope(scope);
    };

    requestTokenRefresh = async (onRefreshError = false, refreshToken, accesstoken) => {
        console.log('refreshToken', refreshToken);
        console.log('accessToken', accesstoken);
        console.log('onRefreshError', onRefreshError);
        this.oAuthUtil.requestTokenRefresh({ onRefreshError: onRefreshError }, refreshToken, accesstoken);
    };

    // deprecated
    get accessTokenDecoded() {
        return this.oAuthUtil.waitTokensSet()
            .then((tokens) => {
                return jwtHelper.decodeToken(tokens.accessToken);
            });
    }

    getAccessTokenDecoded = async () => {
        const tokens = await this.oAuthUtil.waitTokensSet();
        return jwtHelper.decodeToken(tokens.accessToken);
    };

    // deprecated
    get grantedAuthorities() {
        return this.oAuthUtil.waitTokensSet()
            .then(getGrantedAuthorities).catch(() => {
                return [];
            });
    }

    getGrantedAuthorities = async () => {
        try {
            const tokens = await this.oAuthUtil.waitTokensSet();
            return getGrantedAuthorities(tokens);
        } catch (e) {
            return [];
        }
    };
}

function createOAuthService(oAuthConfig) {
    return new OAuthService(oAuthConfig);
}

export default createOAuthService;
