/* eslint-disable no-unused-vars */
/* eslint-disable valid-jsdoc */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable import/order */
/* eslint-disable no-param-reassign */
/* eslint-disable vars-on-top */
/* eslint-disable no-var */
/* eslint-disable no-redeclare */
/* eslint-disable camelcase */
/* eslint-disable no-use-before-define */
/* eslint-disable react/no-this-in-sfc */
/* eslint-disable prefer-rest-params */
/* eslint-disable func-names */
/* eslint-disable import/no-unresolved */
import React, {
    useState, useEffect, useCallback, useContext
} from 'react';
import _ from 'lodash';
import {
    ModalNextProvider,
    Button,
    ModalNext,
} from '@jutro/components';
// import useHistory
import { useHistory } from 'react-router-dom';
// import useAuthentication
import { TranslatorContext, LocaleService } from '@jutro/locale';
// import custom styles
import styles from './Ineligible-claim.module.scss';
// import UtilService
import UtilService from '../../services/UtilService';
// import appMessage
import appMessage from '../../../app/App.messages';
// import custom message
import message from './Ineligible-claim.messages';
import { useAuthentication } from 'gw-digital-auth-react';

/**
 * creating custom modal
 */
function IneligibleClaim({ ineligibleStatus }) {
    const { grantedAuthorities, getGrantedAuthorities } = useAuthentication();
    const [msg, setMsg] = useState('MSG59');
    const [userRole, setUserRole] = useState(false);
    const [showModal, setShowModal] = useState(true);
    const [adminRole, setAdminRole] = useState(false);
    const history = useHistory();
    const translator = useContext(TranslatorContext);


    /**
     * navigate to home page based on user role
     */
    const navigateBackToHomePage = useCallback(
        () => {
            document.body.classList.remove('noScroll');
            setShowModal(false);
            if (msg === 'MSG59' || msg === 'MSG60' || msg === 'MSG62' || msg === 'MSG75') {
                const path = '/claims';
                history.push(path);
            } else {
                window.location.href = UtilService.businessLink(LocaleService.getStoredLanguage());
            }
        },
        [],
    );

    /**
     * navigate to home page based on current user role
     */
    const navigateToHome = useCallback(
        () => {
            getGrantedAuthorities().then((rolesData) => {
                if (rolesData.length === 0) {
                    document.body.classList.remove('noScroll');
                    setShowModal(false);
                    window.location.href = UtilService
                        .businessLink(LocaleService.getStoredLanguage());
                } else if (rolesData.length) {
                    document.body.classList.remove('noScroll');
                    setShowModal(false);
                    const path = '/claims';
                    history.push(path);
                    window.location.reload();
                }
            });
        },
        [],
    );

    /**
     * navigate to business home page
     */
    const navigateToBusinessHome = useCallback(
        () => {
            document.body.classList.remove('noScroll');
            setShowModal(false);
            window.location.href = UtilService.businessLink(LocaleService.getStoredLanguage());
        },
        [],
    );

    /**
     * @param {srring} role
     * to set user role
     */
    const fetchUserRole = useCallback(
        (roles) => {
            roles.then((rolesData) => {
                rolesData.forEach((data) => {
                    if ((data.authorityLevel).toLowerCase() === 'admin_role') {
                        setAdminRole(true);
                    }
                    if ((data.authorityLevel).toLowerCase() === 'user_role') {
                        setUserRole(true);
                    }
                });
            });
        },
        [],
    );

    /**
     * React useEffect
     */
    useEffect(() => {
        document.body.classList.add('noScroll');
        const currentUserRole = UtilService.getUserRole();
        switch (ineligibleStatus) {
            case '840':
                setMsg('MSG59');
                break;
            case '842':
            case '844':
                if (currentUserRole === '') {
                    setMsg('MSG77');
                } else {
                    setMsg('MSG62');
                }
                break;
            case 'MSG75':
                setMsg('MSG75');
                break;
            case 'MSG77':
                setMsg('MSG77');
                break;
            default:
                setMsg('MSG59');
        }
    }, []);

    return (
        <ModalNext
            isOpen={showModal}
            overlayClassName={styles.overlay}
            className={styles.modalCLass}
            shouldFocusAfterRender="true"
            shouldCloseOnOverlayClick={false}
            shouldCloseOnEsc="false"
        >
            <h2 className={styles.modalHeader}>
                {translator(appMessage[msg])}
            </h2>

            {
                (() => {
                    if (msg === 'MSG62' || msg === 'MSG75' || msg === 'MSG77') {
                        return '';
                    }
                    return (
                        <div className={styles.p20}>
                            {' '}
                            {translator(message.navigateBack)}
                            {' '}
                        </div>
                    );
                })()
            }

            <div className={styles.p20}>
                {
                    (() => {
                        if (msg === 'MSG62') {
                            return (
                                <div className={styles.footer}>
                                    <Button type="secondary" onClick={() => { navigateBackToHomePage(); }}>
                                        {translator(message.navigateToViewClaims)}
                                    </Button>
                                    <Button type="primary" onClick={() => { navigateToBusinessHome(); }}>
                                        {translator(message.navigateToBusinessHome)}
                                    </Button>
                                </div>
                            );
                        } if (msg === 'MSG75') {
                            return (
                                <Button onClick={() => {
                                    navigateBackToHomePage();
                                    window.location.reload();
                                }}
                                >
                                    {translator(message.okay)}
                                </Button>
                            );
                        }
                        if (msg === 'MSG77') {
                            return (
                                <Button onClick={() => {
                                    navigateToHome();
                                }}
                                >
                                    {translator(message.okay)}
                                </Button>
                            );
                        }
                        return (
                            <Button onClick={() => { navigateBackToHomePage(); }}>
                                {translator(message.leaveThisPage)}
                            </Button>
                        );
                    })()
                }
            </div>

        </ModalNext>
    );
}


/**
 * @param {string} contentMessage
 * Set custom modal
 */
function showIneligibleClaimModal(contentMessage) {
    ModalNextProvider.showModal(
        <IneligibleClaim ineligibleStatus={contentMessage} />
    ).then(() => {
    }, _.noop);
}

/**
 * exporting showIneligibleClaimModal function
 */
export default showIneligibleClaimModal;
